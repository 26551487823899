var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "context", staticStyle: { height: "100%" } },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入素材名称或关键字" },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.search($event)
                }
              },
              model: {
                value: _vm.searchValue,
                callback: function($$v) {
                  _vm.searchValue = $$v
                },
                expression: "searchValue"
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "search",
                  attrs: {
                    slot: "prepend",
                    "popper-append-to-body": false,
                    placeholder: "视频"
                  },
                  on: { change: _vm.selectVideoandImg },
                  slot: "prepend",
                  model: {
                    value: _vm.materialType,
                    callback: function($$v) {
                      _vm.materialType = $$v
                    },
                    expression: "materialType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "视频", value: "video" } }),
                  _c("el-option", { attrs: { label: "图片", value: "img" } }),
                  _c("el-option", { attrs: { label: "音频", value: "audio" } })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "searchButton" }, [
            _c("i", {
              staticClass: "iconfont icon-sousuo",
              staticStyle: {
                width: "25px",
                height: "32px",
                cursor: "pointer",
                "padding-top": "15px"
              },
              on: { click: _vm.search }
            })
          ])
        ],
        1
      ),
      _vm.none == false
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.ifLoading,
                  expression: "ifLoading"
                }
              ],
              ref: "middleListContent",
              staticClass: "container",
              attrs: {
                "element-loading-background": "rgba(0, 0, 0, 0.1)",
                "element-loading-text": "素材加载中..."
              }
            },
            [
              _vm.materialType == "video"
                ? _c(
                    "div",
                    {
                      staticClass: "videoContext",
                      staticStyle: {
                        "min-height": "100%",
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "totalORorder" }, [
                          _c("div", { staticClass: "total" }, [
                            _vm._v("共" + _vm._s(_vm.materialTotal) + "个素材")
                          ])
                        ]),
                        _vm.materialData.length
                          ? _c(
                              "div",
                              { staticClass: "material_content" },
                              _vm._l(_vm.materialData, function(item, index) {
                                return _c(
                                  "div",
                                  { key: item.ip_id, staticClass: "video" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "videoSize",
                                        staticStyle: { cursor: "pointor" },
                                        attrs: { id: item.ip_id },
                                        on: {
                                          mouseleave: _vm.imgLeave,
                                          mouseenter: _vm.imgEnter,
                                          click: function($event) {
                                            return _vm.goMaterialDetail(
                                              item.ip_id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          class: [
                                            "gifNode",
                                            item.gifNode ? "gifNode_show" : ""
                                          ],
                                          staticStyle: { cursor: "pointor" },
                                          attrs: { src: item.gifNode, alt: "" }
                                        }),
                                        item.showImg
                                          ? _c("img", {
                                              staticClass: "imgNode",
                                              staticStyle: {
                                                cursor: "pointor"
                                              },
                                              attrs: {
                                                src: item.cover_img,
                                                alt: ""
                                              }
                                            })
                                          : _vm._e(),
                                        !item.cover_img
                                          ? _c("i", {
                                              staticClass:
                                                "iconfont icon-shipin1"
                                            })
                                          : _vm._e()
                                      ]
                                    ),
                                    _c("div", { staticClass: "btns" }, [
                                      _c("img", {
                                        staticClass: "ico_sty",
                                        attrs: {
                                          src: require("@/assets/images/down.png"),
                                          alt: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.download(
                                              item.url,
                                              item.ip_id
                                            )
                                          }
                                        }
                                      }),
                                      _c("img", {
                                        staticClass: "ico_sty",
                                        attrs: {
                                          src: require("@/assets/images/add.png"),
                                          alt: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addList(item.ip_id)
                                          }
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.addCollet(item.ip_id)
                                            }
                                          }
                                        },
                                        [
                                          _vm.collet
                                            ? _c("img", {
                                                staticClass: "ico_sty",
                                                attrs: {
                                                  src: require("@/assets/images/colleted.png")
                                                }
                                              })
                                            : _c("img", {
                                                staticClass: "ico_sty",
                                                attrs: {
                                                  src: require("@/assets/images/collet.png")
                                                }
                                              })
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "video_duration" },
                                      [_vm._v(_vm._s(item.duration))]
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        directives: [
                                          {
                                            name: "delTabIndex",
                                            rawName: "v-delTabIndex"
                                          }
                                        ],
                                        attrs: {
                                          placement:
                                            !_vm.filterNum(index + 1) &&
                                            item.name.length > 24
                                              ? "bottom-end"
                                              : "bottom-start",
                                          "popper-class": "my_material_tooltip",
                                          content: item.name
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "videoTitle one_line_ellipsis",
                                            on: {
                                              click: function($event) {
                                                return _vm.goMaterialDetail(
                                                  item.ip_id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.materialType == "img"
                ? _c(
                    "div",
                    {
                      staticClass: "videoContext",
                      staticStyle: {
                        "min-height": "100%",
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "totalORorder" }, [
                          _c("div", { staticClass: "total" }, [
                            _vm._v("共" + _vm._s(_vm.materialTotal) + "个素材")
                          ])
                        ]),
                        _vm.materialData.length
                          ? _c(
                              "div",
                              { staticClass: "material_content" },
                              _vm._l(_vm.materialData, function(item, index) {
                                return _c(
                                  "div",
                                  { key: item.ip_id, staticClass: "video" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "videoSize img_size",
                                        on: {
                                          click: function($event) {
                                            return _vm.goMaterialDetail(
                                              item.ip_id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: item.cover, alt: "" }
                                        })
                                      ]
                                    ),
                                    _c("div", { staticClass: "btns" }, [
                                      _c("img", {
                                        staticClass: "ico_sty",
                                        attrs: {
                                          src: require("@/assets/images/down.png"),
                                          alt: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.download(
                                              item.url,
                                              item.name
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.addCollet(item.ip_id)
                                            }
                                          }
                                        },
                                        [
                                          _vm.collet
                                            ? _c("img", {
                                                staticClass: "ico_sty",
                                                attrs: {
                                                  src: require("@/assets/images/colleted.png")
                                                }
                                              })
                                            : _c("img", {
                                                staticClass: "ico_sty",
                                                attrs: {
                                                  src: require("@/assets/images/collet.png")
                                                }
                                              })
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "el-tooltip",
                                      {
                                        directives: [
                                          {
                                            name: "delTabIndex",
                                            rawName: "v-delTabIndex"
                                          }
                                        ],
                                        attrs: {
                                          placement:
                                            !_vm.filterNum(index + 1) &&
                                            item.name.length > 24
                                              ? "bottom-end"
                                              : "bottom-start",
                                          "popper-class": "my_material_tooltip",
                                          content: item.name
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "videoTitle one_line_ellipsis",
                                            on: {
                                              click: function($event) {
                                                return _vm.goMaterialDetail(
                                                  item.ip_id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.materialType == "audio"
                ? _c(
                    "div",
                    {
                      staticClass: "videoContext",
                      staticStyle: {
                        "min-height": "100%",
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "totalORorder" }, [
                          _c("div", { staticClass: "total" }, [
                            _vm._v("共" + _vm._s(_vm.materialTotal) + "个素材")
                          ])
                        ]),
                        _vm.materialData.length
                          ? _c(
                              "div",
                              { staticClass: "material_content" },
                              _vm._l(_vm.materialData, function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.ip_id,
                                    staticClass: "audio_item"
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        directives: [
                                          {
                                            name: "delTabIndex",
                                            rawName: "v-delTabIndex"
                                          }
                                        ],
                                        attrs: {
                                          placement:
                                            !_vm.filterNum(index + 1) &&
                                            item.name.length > 24
                                              ? "bottom-end"
                                              : "bottom-start",
                                          "popper-class": "my_material_tooltip",
                                          content: item.name
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "audio_title one_line_ellipsis",
                                            on: {
                                              click: function($event) {
                                                return _vm.goMaterialDetail(
                                                  item.ip_id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "center" }, [
                                      _c(
                                        "div",
                                        { staticClass: "audio_author" },
                                        [_vm._v(_vm._s(item.photographer))]
                                      ),
                                      _c("div", {
                                        ref: "waveform",
                                        refInFor: true,
                                        staticClass: "waveform",
                                        attrs: { id: "waveform" + item.ip_id }
                                      }),
                                      item.isplay
                                        ? _c("span", {
                                            staticClass:
                                              "control_icon control_play",
                                            on: {
                                              click: function($event) {
                                                return _vm.audioPause(item)
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      !item.isplay
                                        ? _c("span", {
                                            staticClass:
                                              "control_icon control_pause",
                                            on: {
                                              click: function($event) {
                                                return _vm.audioPlay(item)
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("div", { staticClass: "btns" }, [
                                        _c("img", {
                                          staticClass: "ico_sty",
                                          staticStyle: {
                                            "margin-right": "5px"
                                          },
                                          attrs: {
                                            src: require("@/assets/images/down.png"),
                                            alt: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              if (
                                                $event.target !==
                                                $event.currentTarget
                                              ) {
                                                return null
                                              }
                                              return _vm.download(
                                                item.url,
                                                item.name
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.addCollet(item.ip_id)
                                              }
                                            }
                                          },
                                          [
                                            _vm.collet
                                              ? _c("img", {
                                                  staticClass: "ico_sty",
                                                  attrs: {
                                                    src: require("@/assets/images/colleted.png")
                                                  }
                                                })
                                              : _c("img", {
                                                  staticClass: "ico_sty",
                                                  attrs: {
                                                    src: require("@/assets/images/collet.png")
                                                  }
                                                })
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "audio_duration" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.duration) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                : _vm._e(),
              _c("el-pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.materialData.length && _vm.ifLoading == false,
                    expression: "materialData.length && ifLoading == false"
                  }
                ],
                attrs: {
                  background: true,
                  "current-page": _vm.videopage,
                  layout: "prev, pager, next, jumper",
                  "page-size": 40,
                  total: _vm.materialTotal,
                  "hide-on-single-page": true
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.none && !_vm.ifLoading
        ? _c("noResult", {
            attrs: {
              iconUrl: "icon-meiyoushoucang",
              iconSty: "font-size:80px",
              textSty: "font-size:18px",
              noResultText: "暂无相关素材"
            }
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "下载",
            visible: _vm.downloadDialog,
            width: "461px",
            top: "30vh",
            "custom-class": "material-dialog",
            "show-close": true,
            "before-close": _vm.downloadClose
          },
          on: {
            "update:visible": function($event) {
              _vm.downloadDialog = $event
            }
          }
        },
        [
          _c(
            "span",
            { staticStyle: { display: "block", "margin-bottom": "21px" } },
            [_vm._v("分辨率：")]
          ),
          _c(
            "div",
            { staticClass: "radio_check" },
            [
              _vm.resolutions.length == 1
                ? _c(
                    "el-radio",
                    { staticClass: "disable_radio", attrs: { label: "标清" } },
                    [_vm._v("标清")]
                  )
                : _vm._e(),
              _vm._l(_vm.resolutions, function(item, index) {
                return _c(
                  "el-radio",
                  {
                    key: index,
                    attrs: { label: item.url },
                    model: {
                      value: _vm.system,
                      callback: function($$v) {
                        _vm.system = $$v
                      },
                      expression: "system"
                    }
                  },
                  [_vm._v(_vm._s(item.resolution))]
                )
              })
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadConfirm }
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "30px" },
                  on: {
                    click: function($event) {
                      _vm.downloadDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }