import { render, staticRenderFns } from "./myCollection.vue?vue&type=template&id=24111aee&scoped=true&"
import script from "./myCollection.vue?vue&type=script&lang=js&"
export * from "./myCollection.vue?vue&type=script&lang=js&"
import style0 from "./myCollection.vue?vue&type=style&index=0&id=24111aee&lang=scss&scoped=true&"
import style1 from "./myCollection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24111aee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\maixun_cxx\\private_library\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24111aee')) {
      api.createRecord('24111aee', component.options)
    } else {
      api.reload('24111aee', component.options)
    }
    module.hot.accept("./myCollection.vue?vue&type=template&id=24111aee&scoped=true&", function () {
      api.rerender('24111aee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/myCollection.vue"
export default component.exports