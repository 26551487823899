<template>
  <div class="context" style="height:100%">
    <!-- 头部 -->
    <div class="header">
      <!-- 搜索框 -->
      <el-input
        @keyup.enter.native="search"
        placeholder="请输入素材名称或关键字"
        v-model="searchValue"
      >
        <el-select
          @change="selectVideoandImg"
          class="search"
          v-model="materialType"
          :popper-append-to-body="false"
          slot="prepend"
          placeholder="视频">
          <el-option label="视频" value="video"></el-option>
          <el-option label="图片" value="img"></el-option>
          <el-option label="音频" value="audio"></el-option>
        </el-select>
      </el-input>
      <!-- 搜索图标按钮 -->
      <div class="searchButton">
        <i
          style="width: 25px; height: 32px; cursor: pointer; padding-top: 15px"
          class="iconfont icon-sousuo"
          @click="search"
        ></i>
      </div>
    </div>
    <div v-if="none == false" class="container" ref="middleListContent" v-loading="ifLoading"  element-loading-background="rgba(0, 0, 0, 0.1)" element-loading-text="素材加载中...">
       <!-- 视频-->
      <div
        v-if="materialType == 'video'"
        class="videoContext"
        style="min-height:100%;display: flex;
        flex-direction: column;
        justify-content: space-between;"
        >
        <div>
          <!-- 统计跟排序 -->
        <div class="totalORorder">
          <div class="total">共{{ materialTotal }}个素材</div>
        </div>
        <!-- 视频区域 -->
        <div v-if="materialData.length" class="material_content">
          <div class="video" v-for="(item , index ) in materialData" :key="item.ip_id">
            <!-- 视频 -->
            <div
              class="videoSize"
              style="cursor: pointor"
              :id="item.ip_id" @mouseleave="imgLeave"  @mouseenter="imgEnter"
              @click="goMaterialDetail(item.ip_id)"
            >
                <img
                    :class="['gifNode',item.gifNode ? 'gifNode_show':'']"
                    style="cursor: pointor"
                    :src="item.gifNode"
                    alt=""
                  />
                  <img
                  class="imgNode"
                    style="cursor: pointor"
                    :src="item.cover_img"
                    v-if="item.showImg"
                    alt=""
                  />
                  <i class="iconfont icon-shipin1" v-if="!item.cover_img"></i>
            </div>
            <!-- 下载,加入观看，收藏列表 -->
            <div
              class="btns"
            >
              <img
                @click="download(item.url,item.ip_id)"
                class="ico_sty"
                src="@/assets/images/down.png"
                alt=""
              />
              <img
                class="ico_sty"
                src="@/assets/images/add.png"
                alt=""
                @click="addList(item.ip_id)"
              />
              <!-- 收藏按钮 -->
              <div @click="addCollet(item.ip_id)">
                <img
                  class="ico_sty"
                  v-if="collet"
                  src="@/assets/images/colleted.png"
                />
                <img class="ico_sty" v-else src="@/assets/images/collet.png" />
              </div>
            </div>
            <!-- 视频时长 -->
            <span class="video_duration">{{ item.duration }}</span>
            <!-- 视频底部标题 -->
        <el-tooltip
            v-delTabIndex
            :placement="!filterNum(index +1) && item.name.length > 24 ?'bottom-end':'bottom-start'"
            popper-class="my_material_tooltip"
            :content="item.name"
          >
            <div
              class="videoTitle one_line_ellipsis"
              @click="goMaterialDetail(item.ip_id)"
            >
              {{ item.name }}
            </div>
          </el-tooltip>
          </div>
        </div>
        <!-- <noResult style="margin-top:15%" v-if="!materialData.length && !ifLoading" iconUrl="icon-meiyoushoucang" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无相关素材"></noResult> -->
        </div>
      </div>

      <!-- 图片 -->
      <div
        v-if="materialType == 'img'"
        class="videoContext"
        style="min-height:100%;display: flex;
      flex-direction: column;
      justify-content: space-between;"
      >
      <div>
          <!-- 统计跟排序 -->
        <div class="totalORorder">
          <div class="total">共{{ materialTotal }}个素材</div>
        </div>
        <!-- 图片区域 -->
        <div class="material_content" v-if="materialData.length">
          <div class="video" v-for="(item,index) in materialData" :key="item.ip_id">
            <!-- 图片 -->
            <div class="videoSize img_size" @click="goMaterialDetail(item.ip_id)">
              <img :src="item.cover" alt="" />
            </div>
            <!-- 下载,收藏按钮 -->
            <div class="btns">
              <!-- 下载按钮 -->
              <img
                class="ico_sty"
                src="@/assets/images/down.png"
                alt=""
                @click="download(item.url, item.name)"
              />
              <!-- 收藏按钮 -->
              <div @click="addCollet(item.ip_id)">
                <img
                  class="ico_sty"
                  v-if="collet"
                  src="@/assets/images/colleted.png"
                />
                <img class="ico_sty" v-else src="@/assets/images/collet.png" />
              </div>
            </div>
            <!-- 图片底部标题 -->
          <el-tooltip
                v-delTabIndex
                :placement="!filterNum(index +1) && item.name.length > 24 ?'bottom-end':'bottom-start'"
                popper-class="my_material_tooltip"
                  :content="item.name"
                >
                  <div
                    class="videoTitle one_line_ellipsis"
                    @click="goMaterialDetail(item.ip_id)"
                  >
                    {{ item.name }}
                  </div>
                </el-tooltip>
          </div>
        </div>
        <!-- <noResult style="margin-top:15%" v-if="!materialData.length && !ifLoading" iconUrl="icon-meiyoushoucang" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无相关素材"></noResult> -->
      </div>
      </div>

      <!--音频-->
      <div
        v-if="materialType == 'audio'"
        class="videoContext"
        style="min-height:100%;display: flex;flex-direction: column;justify-content: space-between;">
      <div>
        <div class="totalORorder">
          <div class="total">共{{ materialTotal }}个素材</div>
        </div>
        <div class="material_content" v-if="materialData.length">
            <div class="audio_item" v-for="(item,index) in materialData" :key="item.ip_id">
              <el-tooltip v-delTabIndex :placement="!filterNum(index +1) && item.name.length > 24 ?'bottom-end':'bottom-start'" popper-class="my_material_tooltip" :content="item.name">
                  <div class="audio_title one_line_ellipsis" @click="goMaterialDetail(item.ip_id)">
                    {{ item.name }}
                  </div>
              </el-tooltip>
              <div class="center">
                <div class="audio_author">{{item.photographer}}</div>
                <div :id="'waveform'+item.ip_id" ref="waveform" class="waveform">
                  <!-- Here be the waveform -->
                </div>
                <span class="control_icon control_play" @click="audioPause(item)" v-if="item.isplay"></span>
                <span class="control_icon control_pause" @click="audioPlay(item)" v-if="!item.isplay"></span>
                <div class="btns">
                  <img class="ico_sty" src="@/assets/images/down.png" alt="" style="margin-right:5px;" @click.stop.self="download(item.url, item.name)"/>
                  <div @click="addCollet(item.ip_id)" style="display:inline-block;">
                    <img class="ico_sty" v-if="collet" src="@/assets/images/colleted.png"/>
                    <img class="ico_sty" v-else src="@/assets/images/collet.png" />
                  </div>
                </div>
              </div>
              <div class="audio_duration">
                {{item.duration}}
              </div>
            </div>
        </div>
        <!-- <noResult style="margin-top:15%" v-if="!materialData.length  && !ifLoading" iconUrl="icon-meiyoushoucang" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无相关素材"></noResult> -->
       </div>
      </div>
      <el-pagination
        :background="true"
        :current-page="videopage"
        v-show="materialData.length && ifLoading == false"
        @current-change="handleCurrentChange"
        layout="prev, pager, next, jumper"
        :page-size="40"
        :total="materialTotal"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>

    <noResult v-if="none && !ifLoading" iconUrl="icon-meiyoushoucang" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无相关素材"></noResult>
    <!-- 下载提示对话框 -->
    <el-dialog
      title="下载"
      :visible.sync="downloadDialog"
      width="461px"
      top="30vh"
      custom-class="material-dialog"
      :show-close="true"
      :before-close="downloadClose"
    >
      <span style="display: block; margin-bottom: 21px">分辨率：</span>
      <div class="radio_check">
        <el-radio label="标清" v-if="resolutions.length == 1" class="disable_radio">标清</el-radio>
        <el-radio
          v-for="(item, index) in resolutions"
          :key="index"
          v-model="system"
          :label="item.url"
          >{{ item.resolution }}</el-radio
        >
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadConfirm">确 定</el-button>
        <el-button @click="downloadDialog = false" style="margin-right: 30px"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { preventSpaceScroll } from "../plugins/solvemethod";
import noResult from '../component/noResult.vue'
import $ from "jquery";
import WaveSurfer from 'wavesurfer.js'

export default {
  inject: ["reload"],
  components: {
      noResult,
  },
  data() {
    return {
      materialType: this.getCache(), //做选择视频跟选择图片的缓存
      collet: true, // 是否已经收藏
      showbtns: false, // 显示收藏下载等按钮
      materialTotal: 0, // 素材总数
      materialData: [], // 素材数据
      searchValue: "", // 搜索框绑定的值
      downloadDialog: false, // 显示隐藏下载对话框
      system: "", // 下载对话框选中的选项
      downSourse: "", // 视频的下载源跟图片的下载源
      resolutions: [], //下载的分辨率
      ifLoading: true,
      none: false,//素材为空
      videopage: 0,
      // matchMaterial: false,
    };
  },
  methods: {
    filterNum (n) {
      if (n % 5 == 1 || n % 5 == 2 || n % 5 == 3 ) {
        return true
      }else{
        return false
      }
    },
    audioPlay(item) {
      this.materialData.forEach((item1,i)=>{
        if(item1.ip_id != item.ip_id){
          item1.wavesurfer.pause()
          item1.isplay = false
        }
      })
      item.isplay = true
      item.wavesurfer.play()
    },
    audioPause(item) {
      item.isplay = false
      item.wavesurfer.pause()
    },
    // 搜索
    search() {
      if (this.searchValue.trim() == "") {
        this.$message.error("请输入素材名称或关键字");
      } else {
        this.videopage = 1
        // 关键词搜索视频
        let formData = {}
        switch (this.materialType) {
          case 'video':
            formData = { type: 1, page: 1, search_name: this.searchValue }
            break;
          case 'img':
            formData = { type: 2, page: 1, search_name: this.searchValue }
            break;
          case 'audio':
            formData = { type: 3, page: 1, search_name: this.searchValue }
            break;
        }
        this.getMaterial(formData)
      }
    },
    //做选择视频跟选择图片的缓存
    getCache() {
      // 如果缓存为空，默认视频video
      if (sessionStorage.getItem("materialType1") == null) {
        return "video";
        //如果缓存不为空,默认图片 img
      } else {
        return sessionStorage.getItem("materialType1");
      }
    },
    //跳转至素材详情
    goMaterialDetail(id) {
      const { href } = this.$router.resolve({
        name: "materialDetail",
        query: { ip_id: id, materialType: this.materialType },
      });
      window.open(href, "_blank");
    },

    selectVideoandImg(e) {
      // 清空搜索框值
      this.searchValue = "";
      let formData = {}
      switch (e) {
        case 'video':
          sessionStorage.setItem("materialType1", "video")
          formData = { type: 1, page: 1, search_name: this.searchValue }
          break;
        case 'img':
          sessionStorage.setItem("materialType1", "img")
          formData = { type: 2, page: 1, search_name: this.searchValue }
          break;
        case 'audio':
          sessionStorage.setItem("materialType1", "audio")
          formData = { type: 3, page: 1, search_name: this.searchValue }
          break;
      }
      this.getMaterial(formData)
    },
     //  视频鼠标移入
    imgEnter(e) {
      this.materialData.forEach((item,i)=>{
      item.gifNode = ''
      if(e.target.id == item.ip_id){
        item.gifNode = item.cover
        item.imgNode = ''
        item.showImg = false
      }
      })

    },
    // 视频鼠标移出
    imgLeave(e) {
       this.materialData.forEach((item,i)=>{
        item.gifNode = ''
        item.showImg = true
      })
    },
    // 取消收藏
    addCollet(id) {
      let tp = ""
      let formData = {}
      switch (this.materialType) {
        case 'video':
          tp = 1
          formData = { type: 1, page: 1, search_name: this.searchValue}
          break;
        case 'img':
          tp = 2
          formData = { type: 2, page: 1, search_name: this.searchValue}
          break;
        case 'audio':
          tp = 3
          formData = { type: 3, page: 1, search_name: this.searchValue}
          break;
      }

      let formData1 = new FormData();
      formData1.append("ip_id", id);
      formData1.append("type", tp);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_collect",
        data: formData1,
      }).then((res) => {
        this.getMaterial(formData)
      }).catch(error => console.log(error));
    },

    // 添加播放列表事件
    addList(id) {
      let formData = new FormData();
      formData.append("ip_id", id);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_watch_record",
        data: formData,
      }).then((res) => {
        // console.log(res);
        // 加入成功
        if (res.data.status == 0) {
          this.$store.commit("editAdd", true);
          setTimeout(() => {
            this.$store.commit("editAdd", false);
          }, 1000);
        }
      }).catch(error => console.log(error));
    },
    // 下载按钮
    download(downSourse, name) {
      // 将下载源保存
      this.downSourse = downSourse;
      // 如果是视频
      if (this.materialType == "video") {
        let formData = new FormData();
        formData.append("ip_id", name);
        this.$http({
          url: "/upload/get_download_res",
          method: "POST",
          data: formData,
        }).then((res) => {
          // console.log(res.data);
          if (res.data.status == 0) {
            this.resolutions = res.data.result;
            this.system = res.data.result[0].url;
            // 弹出下载框
            this.downloadDialog = true;
          }
        }).catch(error => console.log(error));
      }
      //如果是图片
      if (this.materialType == "img" || this.materialType == "audio") {
        this.downloadImg(this.downSourse, name);
      }
    },
    // 下载对话框确认事件
    downloadConfirm() {
      // 切换视频配置源 然后视频下载。
      let link = document.createElement('a');
      link.href = this.system  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
      this.downloadDialog = false;
    },
    // 下载图片 传入下载地址
    downloadImg(imgsrc, name) {
      let download_name = ''
      let link = document.createElement('a');
      link.download = download_name  // 下载文件的名字
      link.href = imgsrc  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
    },

    setAudio(list) {
      // console.log(list)
      for(var i = 0; i < list.length; i++) {
        list[i].wavesurfer = WaveSurfer.create({
          height: 24,
          container: `#waveform${list[i].ip_id}`,//绑定容器，第一种方法
          cursorColor:'transparent',
          // maxCanvasWidth:60,
          barGap:1.8,
          // barWidth:1.2,
          barWidth:2,
          barHeight:16,
          barMinHeight:0.5,
          // forceDecode: true,
          backend: 'MediaElement',
          waveColor: '#2267A8',
          progressColor: '#2A9CFF',
          // mediaControls: false,
          audioRate: '1',
          hideScrollbar: true
        })
        list[i].wavesurfer.load(list[i].short_video ? list[i].short_video : list[i].url,list[i].audio_shape,'metadata')//加载音频
        let j = i
        list[i].wavesurfer.on('finish', function () {
          list[j].wavesurfer.stop(); //回到开始并停止
          list[j].isplay = false;
        });

      }
    },
    getMaterial(formData) {
      this.materialData = []
      this.ifLoading = true
      // 请求视频
      this.$http({
        url: "vw_material/mycollectlist",
        method: "GET",
        params: formData,
      }).then((res) => {
        if (res.data.status == 0) {
          this.materialTotal = res.data.total;
          this.ifLoading = false
          this.none = false
          // 当前没有符合条件的素材
          // if(log == '1' && res.data.result.length == 0){
          //   this.matchMaterial = true
          //     return
          // }
          // 没素材
          if(res.data.result.length == 0){
            this.none = true
            return
          }

          if(this.materialType == "video"){
            res.data.result.forEach((item,i)=>{
              item.gifNode = ''
              item.showImg = true
            })
          }
          if(this.materialType == 'audio'){
            res.data.result.forEach((item,i)=>{
              item.isplay = false
            })
            this.$nextTick(()=>{
              this.setAudio(res.data.result)
            })
          }
          this.materialData = res.data.result;
        }
      }).catch(error => console.log(error));
    },
    // 下载对话框的关闭
    downloadClose(done) {
      this.downloadDialog = false;
    },
    // 选择某一页
    handleCurrentChange(val) {
      this.$nextTick(() => {
        this.$refs.middleListContent.scrollTop = 0;
      });
      // 搜索当前页的数据
      let formData = {}
      switch (this.materialType) {
        case 'video':
          formData = { type: 1, page: val, search_name: this.searchValue}
          break;
        case 'img':
          formData = { type: 2, page: val, search_name: this.searchValue}
          break;
        case 'audio':
          formData = { type: 3, page: val, search_name: this.searchValue}
          break;
      }
      this.getMaterial(formData)
    },
  },
  created() {
    this.$store.commit("myCollectionPage", false);
    let formData = {}
    switch (this.getCache()) {
      case 'video':
        formData = { type: 1, page: 1, search_name: this.searchValue}
        break;
      case 'img':
        formData = { type: 2, page: 1, search_name: this.searchValue}
        break;
      case 'audio':
        formData = { type: 3, page: 1, search_name: this.searchValue }
        break;
    }
    this.getMaterial(formData)
  },
  watch: {
    materialType() {
      // console.log(this.materialType);
      if (this.materialType == "img") {
        this.$nextTick(() => {
          $(".img_size").each((i, item) => {
            let video_boxW = $(item).width();
            $(item).css("height", video_boxW * 0.587 + "px");
          });
        });
      }
    },
    myCollectionPage(val) {
      if (val == true) {
        this.reload();
      }
    },
  },
  computed: {
    myCollectionPage: {
      get() {
        return this.$store.state.myCollection;
      },
      set(val) {
        // this.$store.commit('myCollectionPage', val)
      },
    },
  },
  mounted() {
    window.onresize = () => {
      $(".img_size").each((i, item) => {
        let video_boxW = $(item).width();
        $(item).css("height", video_boxW * 0.587 + "px");
      });
    };
    preventSpaceScroll($(".videoContext"));
  },

  beforeDestroy() {
    sessionStorage.removeItem("materialType1");
  },
  destroyed() {
    this.$store.commit("myCollectionPage", false);
  },
};
</script>

<style lang='scss' scoped>
@import '~@/assets/css/mixin.scss';
// 头部搜索框
.header {
  height: 92px;
  background: #161626;
  display: flex;
  justify-content: center;
  .el-input-group {
    line-height: normal;
    display: inline-table;
    width: 55vw;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    height: 50px;
  }
  /deep/.el-input-group__prepend {
    border-right: 0;
    width: 59px;
    height: 48px;
    background: #2D2D3D;
    opacity: 1;
    border-radius: 6px 0px 0px 6px;
    border: 0px solid #dcdfe6;
    color: rgba(255, 255, 255, 0.9);
     .el-popper[x-placement^=bottom] {
     margin-top: 1px;
    }
  }
  /deep/.el-input__inner {
    height: 50px;
    background: #212130;
    border: 0px solid;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color: rgba(255, 255, 255, 0.9);
  }

  /deep/.el-input__suffix {
    right: 20px;
    transition: all 0.3s;
    pointer-events: none;
  }
  // 图片视频下拉框切换
  /deep/.el-select-dropdown {
    left: 0px !important;
    border: 0px solid;
    background: #2D2D3D;
    .el-scrollbar{
      border-radius: 5%;
    }
    .el-select-dropdown__list{
      padding: 0px 0;
    }
    .el-select-dropdown__item {
      background: #2D2D3D;
      color: #f7f7f7;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
    }
    .el-select-dropdown__item.hover {
      background: unset;
      font-size: 14px;
      color: #2a9cff;
    }
    .selected {
      color: #2a9cff;
      font-size: 14px;
    }
  }

  .searchButton {
    margin-top: 20px;
    height: 50px;
    background: #212130;
    border-radius: 0px 6px 6px 0px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
// 统计跟排序
.totalORorder {
  display: flex;
  justify-content: space-between;
  .total {
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.6;
    margin-top: 47px;
    margin-left: 3vw;
  }
}
// 素材样式
.material_content {
  margin-top: 28px;
  .video {
    position: relative;
    display: inline-block;
    width: 17%;
    margin: 0 1%;
    margin-bottom: 25px;
    &:nth-child(5n + 1) {
      margin-left: 3.5%;
    }
    &:nth-child(5n + 5) {
      margin-right: 3.5%;
    }
    .video_duration {
      position: absolute;
      bottom: 30px;
      right: 2px;
      z-index: 99;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 4px 6px;
    }
    .videoSize {
      @include common-item(100%,160px);
      .gifNode{
        width: 0;
        height: 0;
      }
      .gifNode_show{
        width: auto;
        height: auto;
      }
      img {
        cursor:pointer;
      }
    }
    .btns {
      display: none;
    }
  }
  .video:hover {
    .btns {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      top: 0;
      right: 0px;
      z-index: 99;
      .ico_sty{
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
    .video_duration{
      display:none
    }
  }
  .audio_item{
    position: relative;
    display: inline-block;
    width: 21.5%;
    margin: 0 1%;
    margin-bottom: 25px;
    background-color: #181827;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    height: 154px;
    padding: 20px;
    box-sizing: border-box;
    transition: all .3s ease;
    &:nth-child(4n + 1) {
      margin-left: 3.5%;
    }
    &:nth-child(4n + 4) {
      margin-right: 4.5%;
    }
    .center{
      // display: flex;
      // justify-content: space-between;
      margin-top: 24px;
      .control_icon{
        display: inline-block;
        width: 23px;
        height: 23px;
        position: absolute;
        bottom: 22px;
        left: 20px;
        z-index: 9999;
      }
      .control_play{
        background: url("../assets/images/audio-playing.png") no-repeat;
        background-size: 100%;
      }
      .control_pause{
        background: url("../assets/images/audio-stop.png") no-repeat;
        background-size: 100%;
      }
      .waveform{
        width: 64%;
        position: absolute;
        left: 48px;
        bottom: 22px;
        overflow-x: hidden;
        @media (max-width: 1680px) {
          width: 56%;
        }
        @media (max-width: 1440px) {
          width: 50%;
        }
      }
    }
    .btns {
      z-index: 99;
      display: none;
      float: right;
      .ico_sty{
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    &:hover{
      transition: all .3s ease;
      background-color: #2E2E3C;
      // vertical-align: top;
      .btns{
        display: block;
      }
    }
    .audio_title{
      cursor: pointer;
      width: 100%;
      &:hover {
        color: #2a9cff;
      }
    }
    .audio_author{
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
      float: left;
    }
    .audio_duration{
      position: absolute;
      bottom: 25px;
      right: 20px;
      font-size: 12px;
      // color: rgba(255, 255, 255, 0.9);
    }
  }
}
.container {
  height: 83vh;
  @include scrollbar(8px,7px);
  /deep/.el-loading-spinner .circular{
    width: 36px;
    height: 36px;
  }
  /deep/.el-loading-spinner .path{
    stroke: #fff;
    stroke-width: 3;
  }
  /deep/.el-loading-spinner .el-loading-text{
    color: #fff;
    margin-top: 10px;
  }
}
.videoTitle {
  cursor: pointer;
  margin-top: 12px;
  width: 100%;
  &:hover {
    color: #2a9cff;
  }
}
// 分页样式
.el-pagination {
  text-align: center;
  padding: 1vh 5px 0px 5px;
  /deep/.btn-prev {
    background: #292833;
    color: #fff;
  }
  /deep/.btn-next {
    background: #292833;
    color: #fff;
  }
  /deep/.el-pager li {
    background: #292833;
    color: #fff;
  }
  /deep/.el-pager li.active {
    color: #fff;
    background: #409eff;
    border-radius: 20%;
  }
  /deep/.el-pagination__jump {
    color: #fff;
  }
  /deep/.el-input__inner {
    background: #292833;
    color: #fff;
    border: 1px solid #3e3d4c;
  }
}
</style>
<style lang='scss'>
.my_material_tooltip.el-tooltip__popper{
    padding: 6px 10px;
  }
// 下载对话框
.el-dialog.material-dialog{
      background-color: #292939;
      border-radius: 15px;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
      padding: 8px 21px 21px;
      .el-dialog__header{
        text-align: center;
        padding: 0;
        padding-bottom: 6px;
        .el-dialog__title{
          color: #fff;
          font-size: 16px;
          letter-spacing: 30px;
        }
        .el-dialog__headerbtn{
          top: 10px;
          .el-dialog__close{
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .el-dialog__body{
        color: #fff;
        background-color: #0F0F1E;
        font-size: 14px;
        padding: 38px 69px 0 69px;
        .radio_check{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .disable_radio{
            .el-radio__inner{
              background-color: rgba(255,255,255,0.5);
              border: inherit;
            }
            .el-radio__label{
              color: rgba(255,255,255,0.5);
            }
          }
          .el-radio{
            width: 50%;
            margin-right: 0;
            margin-bottom: 18px;
            color: #fff;
          }
          .el-radio__inner{
            width: 16px;
            height: 16px;
          }
          .is-checked{
            .el-radio__inner{
              width: 16px;
              height: 16px;
              border: 3px solid #fff;
              &::after{
                background-color: inherit;
              }
            }
          }
        }
      }
      .el-dialog__footer{
        padding: 20px 69px 45px 69px;
        background-color: #0F0F1E;;
        border-radius: 0px 0px 10px 10px;
        .dialog-footer{
          display: flex;
          justify-content: space-between;
          .el-button{
            border-radius: 20px;
            border: 1px solid #5E5F72;
            background-color: #0F0F1E;;
            color: #fff;
            padding: 6px 15px;
          }
        }
      }
  }
</style>
